import axios from 'axios'
import { Message } from 'element-ui'
// import { MessageBox, Message } from 'element-ui'
// import store from '@/store'
// import { getToken } from '@/utils/auth'

// create an axios instance
import Router from '../router/index.js'

const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 30000 // request timeout
})
function getCookie(cookieName) {
  var allcookies = document.cookie
  // 索引长度，开始索引的位置
  var cookiePos = allcookies.indexOf(cookieName)

  // 如果找到了索引，就代表cookie存在,否则不存在
  if (cookiePos !== -1) {
    // 把cookie_pos放在值的开始，只要给值加1即可
    // 计算取cookie值得开始索引，加的1为“=”
    cookiePos = cookiePos + cookieName.length + 1

    // 计算取cookie值得结束索引
    var cookieEnd = allcookies.indexOf(';', cookiePos)

    if (cookieEnd === -1) {
      cookieEnd = allcookies.length
    }
    // 得到想要的cookie的值
    // var value = unescape(allcookies.substring(cookie_pos, cookie_end))
    var value = allcookies.substring(cookiePos, cookieEnd)
  }
  return value
}

service.interceptors.request.use(
  config => {
    config.headers['X-CSRFToken'] = getCookie('csrftoken')
    return config
  },
  error => {
    console.log(error) // for debug
    return Promise.reject(error)
  }
)

// // request interceptor
// service.interceptors.request.use(
//   config => {
//     // do something before request is sent

//     if (store.getters.token) {
//       // let each request carry token
//       // ['X-Token'] is a custom headers key
//       // please modify it according to the actual situation
//       config.headers['X-Token'] = getToken()
//     }
//     return config
//   },
//   error => {
//     // do something with request error
//     console.log(error) // for debug
//     return Promise.reject(error)
//   }
// )

// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
   */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  response => {
    const { status, data, message } = response.data
    // console.log('status:', status)
    // console.log('data:', data)
    // Message.info(message)
    if (status === 401) {
      Message.warning('登录状态失效,请重新登录')
      // eslint-disable-next-line no-case-declarations
      const origin = window.localStorage.getItem('origin')
      if (typeof origin === 'undefined' || origin == null || origin === '') {
        Router.push({ path: '/401' })
      } else {
        window.location.href = origin
      }
      return
    }
    return data

    // switch (data.code) {
    //   case 400:
    //     Message.error(data)
    //     return Promise.reject(new Error(data.msg))
    //   case 401:
    //     Message.warning('登录状态失效,请重新登录')
    //     // eslint-disable-next-line no-case-declarations
    //     const origin = window.localStorage.getItem('origin')
    //     if (typeof origin === 'undefined' || origin == null || origin === '') {
    //       Router.push({ path: '/401' })
    //     } else {
    //       window.location.href = origin
    //     }
    //     return Promise.reject(new Error(data.msg))
    //   case 403:
    //     Message.error(data.msg)
    //     return Promise.reject(new Error(data.msg))
    //   case 404:
    //     Message.error(data.msg)
    //     return Promise.reject(new Error(data.msg))
    //   case 200:
    //     // Message.success(data.msg)

    //     return data
    //   case 201:
    //     Message.success('创建成功')
    //     return data.data
    //   default:
    //     Message.error(data.msg)
    //     return data.data
    // }
  },
  error => {
    // const { status } = error.response
    // if (status === 401) {
    //   Message.warning('登录状态失效,请重新登录')
    //   const origin = window.localStorage.getItem('origin')
    //   if (typeof origin === 'undefined' || origin == null || origin === '') {
    //     Router.push({ path: '/401' })
    //   } else {
    //     window.location.href = origin
    //   }
    // } else if (status === 403) {
    //   const msg = error.response.data.detail
    //   Message.warning(msg || '返回数据错误！')
    // }
    const { status } = error.response
    const { message, data } = error.response.data
    // console.log('失败响应状态', error.response.data, status)
    switch (status) {
      case 400:
        if (data === null || data === undefined) {
          Message.error(message)
        } else {
          for (var errorfield in data) {
            const errorinfos = data[errorfield]
            for (var errorinfoindex in errorinfos) {
              Message.error(errorfield + ': ' + errorinfos[errorinfoindex])
            }
          }
        }
        return Promise.reject(new Error(data))
      case 401:
        console.log('401', status)
        Message.warning(message)
        // eslint-disable-next-line no-case-declarations
        const origin = window.localStorage.getItem('origin')
        if (typeof origin === 'undefined' || origin == null || origin === '') {
          Router.push({ path: '/login' })
        } else {
          window.location.href = origin
        }
        return Promise.reject(new Error(data))
      case 403:
        Message.error(message)
        return Promise.reject(new Error(data))
      case 404:
        Message.error('接口不存在')
        return Promise.reject(new Error(data))
      case 405:
        Message.error('不允许的请求方式')
        return Promise.reject(new Error(data))
      default:
        Message.error(message)
        return Promise.reject(new Error(data))
    }
    // console.log(error)
  }
)

export default service
