import defaultSettings from '@/settings'

const { showSettings, fixedHeader, sidebarLogo } = defaultSettings

const state = {
  showSettings: showSettings,
  fixedHeader: fixedHeader,
  sidebarLogo: sidebarLogo,
  tabs: []
}

const mutations = {
  CHANGE_SETTING: (state, { key, value }) => {
    if (state.hasOwnProperty(key)) {
      state[key] = value
    }
  },
  SET_TABS(state) {
    state.tabs = JSON.parse(localStorage.getItem('tagList'))
  },

  // 添加群组
  pushGroupId(state, { name, id, pathName }) {
    const path = pathName + id

    if (
      !state.tabs.some(item => {
        return item.path === path
      })
    ) {
      state.tabs.push({
        name: name,
        path: path,
        groupId: id

      })
      localStorage.setItem('tagList', JSON.stringify(state.tabs))
    } else {
      for (let i = 0; i < state.tabs.length; i++) {
        if (state.tabs[i].path === path) {
          state.tabs.splice(i, 1, {
            name: name,
            path: path,
            groupId: id

          })
          break
        }
      }
    }
  }
}

const actions = {
  changeSetting({ commit }, data) {
    commit('CHANGE_SETTING', data)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
