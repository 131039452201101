<template>
  <div class="navbar">
    <hamburger
      :is-active="sidebar.opened"
      class="hamburger-container"
      @toggleClick="toggleSideBar"
    />

    <div class="breadcrumb-time">{{ date.day }}&nbsp;{{ date.time }}</div>

    <div class="right-menu">
      <div class="title-item" @click="goIndex">
        <img title="首页" src="@/assets/imgs/home.png" alt="" />
        <!-- <div>返回首页</div> -->
      </div>
      <div class="title-item">
        <el-popover placement="bottom" width="190" trigger="hover">
          <div class="popover-box">
            <img
              style="width:165px;height:auto"
              src="@/assets/imgs/wx1.jpg"
              alt=""
            />
            <div
              style="color: red;
               text-align: center;
                font-size: 20px;
                font-weight: 600;"
            >
              15054576533
            </div>
          </div>
          <div slot="reference">
            <img src="@/assets/imgs/wx.png" alt="" />
            <!-- <div style="margin-top:0">联系客服</div> -->
          </div>
        </el-popover>
      </div>

      <div class="title-item" @click="logout">
        <img title="退出" src="@/assets/imgs/out.png" alt="" />
        <!-- <div>退出</div> -->
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
// import Breadcrumb from '@/components/Breadcrumb'
import Hamburger from '@/components/Hamburger'
import { logOut } from '@/api/user'

export default {
  data() {
    return {
      date: {
        time: '00:00:00',
        week: '星期日',
        day: '2022-01-01'
      }
    }
  },
  components: {
    // Breadcrumb,
    Hamburger
  },
  computed: {
    ...mapGetters(['sidebar', 'avatar'])
  },
  created() {
    this.getTime()
  },
  methods: {
    toggleSideBar() {
      this.$store.dispatch('app/toggleSideBar')
    },
    async logout() {
      logOut().then(resp => {
        const origin = window.localStorage.getItem('origin')

        if (origin) {
          window.location.href = origin
        } else {
          this.$router.push('/401')
        }
      })
    },
    goIndex() {
      const origin = window.localStorage.getItem('origin')
      if (origin) {
        window.location.href = origin + 'index.html'
      } else {
        this.$router.push('/401')
      }
    },
    getTime() {
      this.timing = setInterval(() => {
        var myDate = new Date()
        var myYear = myDate.getFullYear() // 获取完整的年份(4位,1970-????)
        var myMonth = myDate.getMonth() + 1 // 获取当前月份(0-11,0代表1月)
        var myToday = myDate.getDate() // 获取当前日(1-31)
        var myDay = myDate.getDay() // 获取当前星期X(0-6,0代表星期天)
        var myHour = myDate.getHours() // 获取当前小时数(0-23)
        var myMinute = myDate.getMinutes() // 获取当前分钟数(0-59)
        var mySecond = myDate.getSeconds() // 获取当前秒数(0-59)
        var week = [
          '星期日',
          '星期一',
          '星期二',
          '星期三',
          '星期四',
          '星期五',
          '星期六'
        ]
        this.date.time =
          this.fillZero(myHour) +
          ':' +
          this.fillZero(myMinute) +
          ':' +
          this.fillZero(mySecond)
        this.date.week = week[myDay]
        this.date.day =
          myYear + '-' + this.fillZero(myMonth) + '-' + this.fillZero(myToday)
      }, 1000)
    },
    fillZero(str) {
      var realNum
      if (str < 10) {
        realNum = '0' + str
      } else {
        realNum = str
      }
      return realNum
    }
  }
}
</script>

<style lang="scss" scoped>
.navbar {
  height: 50px;
  overflow: hidden;
  position: relative;
  background: #fff;
  box-shadow: 0px 2px 5px 0px rgba(124, 124, 127, 0.36);

  .hamburger-container {
    line-height: 46px;
    height: 100%;
    float: left;
    cursor: pointer;
    transition: background 0.3s;
    -webkit-tap-highlight-color: transparent;

    &:hover {
      background: rgba(0, 0, 0, 0.025);
    }
  }

  .breadcrumb-container {
    float: left;
  }

  .right-menu {
    float: right;
    height: 100%;

    display: flex;
    .title-item {
      width: 36px;
      // background: #f7f7f9;
      text-align: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin: 0 2px;
      cursor: pointer;
      img {
        width: 20px;
        height: 20px;
        object-fit: cover;
        align-self: center;
      }
      div {
        margin-top: 5px;
        font-size: 12px;
        font-weight: 300;
        color: #333434;
        // margin-top: -35px;
      }

      :hover {
        color: #1154ee;
      }
    }
  }
  .breadcrumb-time {
    float: left;
    line-height: 50px;
    font-size: 14px;
    color: #676868;
  }
}
</style>
