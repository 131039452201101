import Vue from 'vue'
import VueRouter from 'vue-router'
// import Home from '../views/Home.vue'
import addRouteFun from './administrators'
import Layout from '@/layout'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: Layout,
    redirect: '/account',
    name: 'indexParent',
    children: [
      {
        name: 'Account',
        path: '/account',
        component: () => import('@/views/detect/Account.vue'),
        meta: {
          title: '账号落查',
          icon: 'el-icon-upload2'
        }
      }
    ]
  },
  {
    path: '/email/box',
    component: Layout,
    redirect: '/email',
    name: 'indexParent1',
    children: [
      {
        name: 'email',
        path: '/email',
        component: () => import('@/views/detect/Email.vue'),
        meta: {
          title: '邮箱反查',
          icon: 'el-icon-upload2'
        }
      }
    ]
  },
  {
    path: '/points/box',
    component: Layout,
    redirect: '/points',
    name: 'indexParent2',
    children: [
      {
        name: 'Points',
        path: '/points',
        component: () => import('@/views/detect/Points.vue'),
        meta: {
          title: '积分使用记录',
          icon: 'el-icon-upload2'
        }
      }
    ]
  },
  {
    name: 'Admin',
    path: '/admin',
    component: Layout,
    redirect: '/admin/users',
    meta: {
      title: '管理员',
      icon: 'el-icon-s-custom'
    },
    children: [
      {
        name: 'Users',
        path: 'users',
        component: () => import('@/views/admin/Users.vue'),
        meta: {
          title: '用户管理',
          icon: 'el-icon-s-custom'
        }
      }
    ]
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/login/Login.vue')
  },
  {
    path: '/authLogin',
    name: 'AuthLogin',
    component: () => import('@/views/login/AuthLogin.vue')
  },
  {
    path: '/401',
    name: '401',
    component: () => import('@/views/errorPage/401.vue')
  },
  {
    path: '*',
    name: '404',
    component: () => import('@/views/errorPage/404.vue')
  },
  // 用户详情
  {
    path: '/user',
    component: Layout,
    redirect: '/user/detail',
    name: 'userPage',
    hidden: true,
    children: [
      {
        meta: {
          keepAlive: true
        },
        path: 'detail/:userId',
        name: 'userDetail',
        component: () => import('@/views/detail/UserDetail.vue')
      }
    ]
  },
  // 群组详情
  {
    path: '/group',
    component: Layout,
    redirect: '/group/detail',
    name: 'groupPage',
    hidden: true,
    children: [
      {
        // meta: {
        //   keepAlive: true
        // },
        path: 'detail/:groupId',
        name: 'GroupDetail',
        component: () => import('@/views/detail/GroupDetail.vue')
      }
    ]
  }
]
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
addRouteFun(router)

export default router
