<template>
  <div class="sidebar-logo-container" :class="{ collapse: collapse }">
    <transition name="sidebarLogoFade">
      <router-link
        v-if="collapse"
        key="collapse"
        class="sidebar-logo-link"
        to="/"
      >
        <!-- <img v-if="logo" :src="logo" class="sidebar-logo" />
        <h1 v-else class="sidebar-title">{{ title }}</h1> -->

        <span class="only-logo">W</span>
      </router-link>
      <!-- <router-link v-else key="expand" class="sidebar-logo-link" to="/">
        <img v-if="logo" :src="logo" class="sidebar-logo">
        <h1 class="sidebar-title">{{ title }} </h1>
      </router-link> -->
      <div v-else class="show-sidebar">
        <router-link to="/">
          <div class="show-sidebar-title">{{ title }}</div>
        </router-link>
        <div class="show-sidebar-info">
          <!-- <img :src="require('../../../assets/imgs/p1.jpeg')" alt="" /> -->
          <img :src="logo" alt="" />
          <div class="info-name">
            {{
              info
                ? info.username && info.username !== ''
                  ? info.username
                  : '用户'
                : '用户'
            }}
          </div>
          <!-- <div class="info-type">正式用户</div> -->
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import touPic1 from '@/assets/toupic/tou1.jpg'
import touPic2 from '@/assets/toupic/tou2.jpg'
import touPic3 from '@/assets/toupic/tou3.jpg'
import touPic4 from '@/assets/toupic/tou4.jpg'
import touPic5 from '@/assets/toupic/tou5.jpg'
export default {
  name: 'SidebarLogo',
  props: {
    collapse: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      title: 'GitHub数据分析系统',
      logo: ''
    }
  },
  computed: {
    info() {
      return this.$store.state.user.userInfo
    }
  },
  created() {
    const userInfo = localStorage.getItem('userInfo')
    console.log(this.info)
    let id
    if (userInfo) {
      id = parseInt(JSON.parse(userInfo).id)
      this.picNum(id)
    } else {
      this.logo = touPic4
    }
  },
  methods: {
    picNum(id) {
      const num = id % 5
      if (num === 1) {
        this.logo = touPic1
      } else if (num === 2) {
        this.logo = touPic2
      } else if (num === 3) {
        this.logo = touPic3
      } else if (num === 4) {
        this.logo = touPic4
      } else {
        this.logo = touPic5
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.sidebarLogoFade-enter-active {
  transition: opacity 1.5s;
}

.only-logo {
  font-size: 28px;
  font-weight: 600;
  color: white;
  font-style: italic;
  &:hover {
    color: rgb(228, 194, 194);
  }
}

.sidebarLogoFade-enter,
.sidebarLogoFade-leave-to {
  opacity: 0;
}

.sidebar-logo-container {
  position: relative;
  width: 100%;

  text-align: center;
  overflow: hidden;

  & .sidebar-logo-link {
    height: 100%;
    width: 100%;
    line-height: 50px;
    & .sidebar-logo {
      width: 32px;
      height: 32px;
      vertical-align: middle;
      margin-right: 12px;
    }

    & .sidebar-title {
      display: inline-block;
      margin: 0;
      color: #fff;
      font-weight: 600;
      line-height: 50px;
      font-size: 14px;
      font-family: Avenir, Helvetica Neue, Arial, Helvetica, sans-serif;
      vertical-align: middle;
    }
  }
  .show-sidebar {
    a {
      display: inline !important;
    }
    width: 100%;
    // background: rgb(18,140,126);
    .show-sidebar-title {
      width: 100%;
      height: 60px;
      text-align: center;
      line-height: 60px;
      // background: rgb(18,140,126);
      font-size: 18px;
      font-weight: bold;
      color: #f9f9f9;
    }
    .show-sidebar-info {
      width: calc(100% - 10px);
      padding: 16px 0;
      height: 168px;
      background: #21262d;
      margin: 0 auto;
      text-align: center;
      img {
        margin-top: 10px;
        width: 74px;
        height: 74px;
        object-fit: cover;
        border-radius: 50%;
      }
      .info-name {
        height: auto !important;
        font-size: 22px;

        font-weight: normal;
        color: #ffffff;
      }
      .info-type {
        font-size: 14px;
        height: auto !important;
        color: #89898a;
      }
    }
  }

  &.collapse {
    .sidebar-logo {
      margin-right: 0px;
    }
  }
}
</style>
