<template>
  <div class="tab-page">
    <el-tag
      type="info"
      v-for="(item, i) in tabList"
      :key="i"
      size="mini"
      :class="item.path == $route.path ? 'select-tab' : ''"
      @click.prevent="selecFun(item)"
      @close="closeFun(item, i)"
      :closable="tabList.length > 1 ? true : false"
    >
      {{ item.name }}
    </el-tag>
  </div>
</template>

<script>
import '@/styles/variables.scss'
export default {
  computed: {
    tabList() {
      return this.$store.state.settings.tabs
    },
    variables() {
      return variables
    }
  },
  created() {
    const newList = [
      {
        name: this.$route.meta.title,
        path: this.$route.path,
        componentsName: this.$route.name
      }
    ]

    sessionStorage.setItem('tagList', JSON.stringify(newList))
    this.$store.commit('settings/SET_TABS')
  },
  methods: {
    selecFun(row) {
      this.$router.push(row.path)
    },
    closeFun(row, i) {
      if (this.tabList.length > 1) {
        this.tabList.splice(i, 1)
        localStorage.setItem('tagList', JSON.stringify(this.tabList))
        this.$store.commit('settings/SET_TABS')
        if (this.$route.path === row.path) {
          if (i > 0) {
            const path = this.tabList[i - 1].path
            this.$router.push(path)
          } else {
            const path = this.tabList[0].path
            this.$router.push(path)
          }
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.tab-page {
  width: 100%;
  height: 28px;
  padding: 5px;
  line-height: 23px;
  box-shadow: 0 0 2px #b4b4b4;
  white-space: nowrap;
  overflow: hidden;
  margin-bottom: -4px;
  ::v-deep .el-tag {
    user-select: none;
    cursor: pointer;
    margin: 0 3px;
    padding: 0 8px;
    border-radius: 4px 4px 0 0;
  }
  .select-tab {
    background: rgb(18, 140, 126) !important;
    color: white;
    ::v-deep .el-tag__close {
      color: #ffffff;
    }
  }
}
</style>
